document.addEventListener('DOMContentLoaded', () => {
  const navbarBurgers = document.querySelectorAll('.navbar-burger')

  navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {
      // Get the target from the "data-target" attribute
      const target = el.dataset.target
      const $target = document.getElementById(target)

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active')
      $target.classList.toggle('is-active')
    })
  })
  
  const navbarDropdowns = document.querySelectorAll('.navbar-menu .has-dropdown')
  navbarDropdowns.forEach(el => {
    el.addEventListener('click', () => {
      document.querySelectorAll('.navbar-menu .has-dropdown').forEach(item => {
        if (item != el) {
          item.classList.remove('is-active')
        }
      })
      el.classList.toggle('is-active')
    })  
  })

  const bodySections = document.querySelectorAll('body > *:not(.navbar)')
  bodySections.forEach(el => {
    el.addEventListener('click', () => {
      document.querySelectorAll('.navbar-menu .has-dropdown').forEach(item => {
        if (item != el) {
          item.classList.remove('is-active')
        }
      })
    })
  })
})
